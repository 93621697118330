import React from "react";
import Form from "@rjsf/core";
import {useHistory} from "react-router-dom";
import countries from "../countries.json";
import {setBasicData} from './basic-data.actions';
import {BasicDataState} from './basic-data-state';
import {connect, useSelector} from 'react-redux';
import {setCountryData} from '../country/country.actions';

const properties = {
  firstName: {type: "string", title: "First Name"},
  lastName: {type: "string", title: "Last Name"},
  birthday: {type: "string", title: "Birthday", format: "date"},
  country: {
    type: "string", title: "Citizenship",
    // anyOf:countries.map(c => ({
    //   title: c.name,
    //   enum: [c.id],
    //   type: "string"
    // })),
    enum: countries.map(c => c.name)
  },
  email: {type: "string", title: "Email", format: 'email'},
  phone: {
    type: "string", title: "Phone", format: 'phone', "ui:options": {
      "inputType": "tel"
    }
  },
  address: {type: "string", title: "Current Address"},
};

const schema: any = {
  title: "Personal Data",
  type: "object",
  required: Object.keys(properties),
  properties,
};

const uiSchema: any = {
  "birthday": {
    "ui:widget": "alt-date",
    "ui:options": {
      "yearsRange": [
        1900,
        2012
      ],
      hideNowButton: true,
      hideClearButton: true,
    }
  }
};

const log = (type) => console.log.bind(console, type);

function BasicDataForm(props) {
  const history = useHistory();
  const basicDataState: BasicDataState = useSelector(state => state.basicData);

  return (
    <div>
      <Form schema={schema}
            formData={basicDataState}
            uiSchema={uiSchema}
            onChange={log("changed")}
            onSubmit={(ev) => {
              console.log(ev);
              if(!ev.errors.length) {
                props.setBasicData(ev.formData);
                props.setCountryData({});
                history.push("/additional");
              }
            }}
            onError={log("errors")}>
        <button type="submit">Next</button>
      </Form>
    </div>
  );
}

export default connect(
  null,
  {
    setBasicData,
    setCountryData,
  }
)(BasicDataForm);

import React from "react";
import {Route, Switch} from "react-router-dom";
import Country from './country/Country';
import FinalPage from './FinalPage';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';

import configureStore, {history} from './redux/store';
import BasicDataForm from './basic-data/BasicDataForm';
import AdditionalDataForm from './additional-data/AdditionalDataForm';

export default function App() {
  const {store, persistor} = configureStore({});
  return (
    <Provider store={store}>
      {/*<PersistGate loading={null}*/}
      {/*             persistor={persistor}>*/}
      <ConnectedRouter history={history}>
        <Switch>
          <Route path={`/basic-data`}
                 children={<BasicDataForm />} />
          <Route path={`/additional`}
                 children={<AdditionalDataForm />} />
          <Route path={`/country-questions`}
                 children={<Country />} />
          <Route path={`/final-page`}
                 children={<FinalPage />} />
          <Route path="/">
            <BasicDataForm />
          </Route>
        </Switch>
      </ConnectedRouter>
      {/*</PersistGate>*/}
    </Provider>
  );
}

import {CountryState} from './country-state';
import {CountryActionTypes} from './country.actions';

export const initialCountryState: CountryState = {
  countryData: undefined,
  selectedQuestionId: 'start',
  currentQuestionId: 'start',
  selectedIds: [],
  result: undefined,
  error: undefined,
};

export function countryReducer(state: CountryState = initialCountryState, action): CountryState {
  switch (action.type) {
    case CountryActionTypes.setError:
      return {...state, error: action.error};

    case CountryActionTypes.setCountryData:
      return {
        ...initialCountryState,
        countryData: action.payload.countryData,
      };

    case CountryActionTypes.submitCurrentQuestionId:
      let currentQuestionId = state.selectedQuestionId;
      let qd = state.countryData[currentQuestionId];


      if(!qd) {
        throw new Error('Should not happen!');
      }

      const result = qd?.answers?.length
        ? undefined
        : qd.id;

      return {
        ...state,
        currentQuestionId,
        result,
        selectedIds: [...state.selectedIds, currentQuestionId],
      };

    case CountryActionTypes.setSelectedQuestionId:
      return {...state, selectedQuestionId: action.selectedQuestionId};

    default:
      // throw new Error();
      return state;
  }
}

export default countryReducer;

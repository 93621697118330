import {FormActionTypes} from './basic-data.actions';
import {BasicDataState} from './basic-data-state';

export const initialFormState: BasicDataState = {
  firstName: undefined,
  lastName: undefined,
  birthday: undefined,
  country: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
};

export function basicDataReducer(state: BasicDataState = initialFormState, action): BasicDataState {
  switch (action.type) {
    case FormActionTypes.setBasicData:
      return action.formData;

    default:
      // throw new Error();
      return state;
  }
}

export default basicDataReducer;

import countryReducer from '../country/country.reducer';
import reduceReducers from 'reduce-reducers';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import rootReducer from './root.reducer';
import {createHashHistory} from 'history';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {persistReducer, persistStore} from 'redux-persist';
import basicDataReducer from '../basic-data/basic-data.reducer';
import additionalDataReducer from '../additional-data/additional-data.reducer';

export const history = createHashHistory({
  hashType: 'slash',
  // getUserConfirmation: (message, callback) => callback(window.confirm(message))
});
export default function configureStore(preloadedState) {
  const store = createStore(
    reduceReducers(
      combineReducers({
        country: persistReducer({key: 'country', storage}, countryReducer),
        basicData: persistReducer({key: 'basicData', storage}, basicDataReducer),
        additionalData: persistReducer({key: 'additionalData', storage}, additionalDataReducer),
        router: connectRouter(history)
      }),
      rootReducer
    ),
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
      ),
    ),
  );
  const persistor = persistStore(store);
  return {store, persistor};
}

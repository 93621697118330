import {FormActionTypes} from './additional-data.actions';
import {AdditionalDataState} from './additional-data-state';

export const initialFormState: AdditionalDataState = {
  wasInEuBefore: undefined,
  wasDeported: undefined,
  // deportedDate: undefined,
  wasVisaDenied: undefined,
  wasConvicted: undefined,
  convictionCrime: undefined,
  hasChronicalDiseases: undefined,
};

export function additionalDataReducer(state: AdditionalDataState = initialFormState, action): AdditionalDataState {
  switch (action.type) {
    case FormActionTypes.setAdditionalData:
      return action.formData;

    default:
      // throw new Error();
      return state;
  }
}

export default additionalDataReducer;

import {RootState} from './root';
// import {initialCountryState} from '../country/country.reducer';

export default function rootReducer(state: RootState, action): RootState {
  console.log(state, action);
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      // console.log(action.payload.location.pathname);
      // console.log(action.payload.location.pathname.match(/\/country\/\d/));
      // if(action.payload  && action.payload.location.pathname.match(/\/country/)) {
      //   return  {
      //     country: {
      //       ...initialCountryState,
      //     },
      //     ...state,
      //   }
      // }
      return state;
  }

  return state;
}

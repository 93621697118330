import React, {useEffect} from "react";
import {Link, Redirect, useParams} from "react-router-dom";
import countries from '../countries.json';
import {connect, useSelector} from 'react-redux';
import {setCountryData, setError, setSelectedQuestionId, submitCurrentQuestionId} from './country.actions';
import {CountryState} from './country-state';
import {BasicDataState} from '../basic-data/basic-data-state';
import {useHistory} from "react-router-dom";


function Country(props) {
  useEffect(() => {
    const fetchUrl = `/data/${countryName}.json`;
    fetch(fetchUrl)
      .then(res => res.json())
      .then((result) => {
          props.setCountryData({countryData: result});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          props.setError(error);
        }
      );
  }, []);

  const basicDataState: BasicDataState = useSelector(state => state.basicData);
  const history = useHistory();
  const countryName = basicDataState.country;
  console.log(countryName);

  if(!countryName) {
    // throw new Error('No countryName');
    history.push("/basic");
  }
  const country = countries.find(country => country.name === countryName);
  const state: CountryState = useSelector(state => state.country);
  const {countryData, currentQuestionId, selectedIds,  error} = state;
  const isLoaded = (!!countryData && country) || !!error;

  if(error) {
    return <div>Error: {(error as any)?.message}</div>;
  } else if(!isLoaded) {
    return <div>Loading...</div>;
  } else {
    const qd = countryData[currentQuestionId];
    if(qd) {
      if(qd.answers.length === 0) {
        console.log(props);
        return <Redirect to="/final-page" />;
      }

      return (
        <div>
          <Link to='/'>Back to country overview</Link>
          <h2>{country.name} – Step {selectedIds.length + 1}</h2>
          {/*<h3>{currentQuestionId}</h3>*/}
          <h3>{qd.topic}</h3>
          <h4 dangerouslySetInnerHTML={{__html: qd.text1}} />

          <form onSubmit={(e) => {
            e.preventDefault();
            props.submitCurrentQuestionId();
          }}>
            {qd.answers.map((answer) => <label key={answer.id}
                                               style={{display: "block"}}>
              <input type="radio"
                     name="same"
                     required={true}
                     onChange={(e) => props.setSelectedQuestionId(e.currentTarget.value)}
                     value={answer.id} />
              <span dangerouslySetInnerHTML={{__html: answer.title}} />
            </label>)}


            <div>
              <button type="submit">Next</button>
              <div className="small-print"
                   dangerouslySetInnerHTML={{__html: qd.text2}} />
            </div>
          </form>
        </div>
      );
    } else {
      return <div>No question data yet...</div>;
    }
  }
}

export default connect(
  null,
  {
    submitCurrentQuestionId,
    setSelectedQuestionId,
    setCountryData,
    setError,
  }
)(Country);

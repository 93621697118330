export enum CountryActionTypes {
  submitCurrentQuestionId = 'submitCurrentQuestionId',
  setCountryData = 'setCountryData',
  setSelectedQuestionId = 'setSelectedQuestionId',
  setCountryDataLoaded = 'setCountryDataLoaded',
  setError = 'setError',
}

export function submitCurrentQuestionId() {
  return {type: CountryActionTypes.submitCurrentQuestionId};
}

export function setSelectedQuestionId(selectedQuestionId: string) {
  return {type: CountryActionTypes.setSelectedQuestionId, selectedQuestionId};
}

export function setCountryData(payload: { countryData: { [key: string]: any }}) {
  return {type: CountryActionTypes.setCountryData, payload};
}

export function setError(error: Error) {
  return {type: CountryActionTypes.setError, error};
}

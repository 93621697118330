import React, {useEffect} from "react";
import {connect, useSelector} from 'react-redux';
import {setCountryData} from './country/country.actions';
import {useHistory} from "react-router-dom";
import {BasicDataState} from './basic-data/basic-data-state';
import {AdditionalDataState} from './additional-data/additional-data-state';


function parseSelections(selectedIds: string[], countryData) {
  return selectedIds.map(id => {
    const entry = countryData[id];
    const par = entry.selectedParentId.toString().length > 4
      ? countryData['start']
      : countryData[entry.selectedParentId.toString()] || countryData['start'];

    return par.answers.find(answer => answer.id.toString() === id.toString()).title;
  });
}

function getFormResultText({selectedIds, countryData, result, basicData, additionalData}: {
  selectedIds: string[],
  countryData,
  result: string,
  basicData: BasicDataState,
  additionalData: AdditionalDataState,
}): string {
  const basic = Object.keys(basicData)
    .filter(key => key !== '_persist')
    .map((k) => k + ': ' + basicData[k])
    .join('\n');
  const additional = Object.keys(additionalData)
    .filter(key => key !== '_persist')
    .map((k) => k + ': ' + additionalData[k])
    .join('\n');
  const eduSelections = parseSelections(selectedIds, countryData);
  console.log(basicData, additionalData);

  return basic
    + '\n\n' + additional
    + '\n\n' + eduSelections.map(es => '–' + es).join('\n')
    + '\nEducationCode: ' + result;
}

function toMailToLink({selectedIds, countryData, result, basicData, additionalData}: {
  selectedIds: string[],
  countryData,
  result: string,
  basicData: BasicDataState,
  additionalData: AdditionalDataState,
}): string {
  if(!countryData) {
    return 'NONE';
  }
  const MAIL = 's.stroh@ra-stroh.de';
  const subject = 'Submission from ' + basicData.firstName + ' ' + basicData.lastName;

  const body = getFormResultText({selectedIds, countryData, result, basicData, additionalData});
  const r = `mailto:${MAIL}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  console.log(r.length, r);
  return r;
}

function FinalPage(props) {
  useEffect(() => {
    if(countryData) {
      return;
    }
    if(!countryName) {
      console.warn('Cant do request. No country name');
      // props.setError('Cant do request. No country name');
      return;
    }

    const fetchUrl = `/data/${countryName}.json`;
    fetch(fetchUrl)
      .then(res => res.json())
      .then((result) => {
          props.setCountryData({countryData: result});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          props.setError(error);
        }
      );
  }, []);

  const state = useSelector(state => state);
  const history = useHistory();
  const {countryData, currentQuestionId, selectedIds, error, result} = state.country;
  const countryName = state.basicData.country;
  if(!countryName) {
    // throw new Error('No countryName');
    history.push("/basic");
  }
  const isLoaded = (!!countryData) || !!error;

  const {basicData, additionalData} = state;
  const mailToLink = toMailToLink({selectedIds, countryData, result, basicData, additionalData});

  if(error) {
    return <div>Error: {error.message}</div>;
  } else if(!isLoaded) {
    return <div>Loading...</div>;
  } else {
    const qd = countryData[currentQuestionId];
    const text = getFormResultText({selectedIds, countryData, result, basicData, additionalData});
    return (
      <div>
        <a className="mail-in"
           href={mailToLink}>Click here to mail in results!</a>
        <p>In case the link above doesn't work, please copy the following text and send it via email to
          <a href={'mailto:some@address.com'}>some@address.com</a>:</p>

        <pre>
          {text}
        </pre>
      </div>
    );
  }
}

export default connect(
  null,
  {setCountryData}
)(FinalPage);

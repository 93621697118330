import React from "react";
import Form from "@rjsf/core";
import {useHistory} from "react-router-dom";
import {setAdditionalData} from './additional-data.actions';
import {AdditionalDataState} from './additional-data-state';
import {connect, useSelector} from 'react-redux';

const properties = {
  wasInEuBefore: {type: "boolean", title: "Have you ever been in the EU before?"},
  wasDeported: {type: "boolean", title: "Have you ever been deported from the EU?"},
  // deportedDate: {type: "string", title: "When have you been deported?", format: "date"},
  wasVisaDenied: {type: "boolean", title: "Have you ever been denied a visa for the EU?"},
  wasConvicted: {type: "boolean", title: "Have you ever been convicted by a criminal court?"},
  convictionCrime: {type: "string", title: "For what crime have you been convicted?"},
  hasChronicalDiseases: {type: "boolean", title: "Do you have any chronical diseases?"},
};

const schema: any = {
  title: "Additional Information",
  type: "object",
  required: [
    'wasInEuBefore',
    'wasDeported',
    'wasVisaDenied',
    'wasConvicted',
    'hasChronicalDiseases',
  ],
  properties,
  dependencies: {
    // wasDeported: ["deportedDate"],
    convictionCrime: ["wasConvicted"]
  }
};

const uiSchema: any = {
  wasInEuBefore: {
    "ui:widget": "radio"
  },
  wasDeported: {
    "ui:widget": "radio"
  },
  wasVisaDenied: {
    "ui:widget": "radio"
  },
  wasConvicted: {
    "ui:widget": "radio"
  },
  hasChronicalDiseases: {
    "ui:widget": "radio"
  },
};

const log = (type) => console.log.bind(console, type);

function AdditionalDataForm(props) {
  const history = useHistory();
  const additionalDataState: AdditionalDataState = useSelector(state => state.additionalData);

  return (
    <div>
      <Form schema={schema}
            formData={additionalDataState}
            uiSchema={uiSchema}
            onChange={log("changed")}
            onSubmit={(ev) => {
              console.log(ev);
              if(!ev.errors.length) {
                props.setAdditionalData(ev.formData);
                history.push("/country-questions");
              }
            }}
            onError={log("errors")}>
        <button type="submit">Next</button>
      </Form>
    </div>
  );
}

export default connect(
  null,
  {
    setAdditionalData,
  }
)(AdditionalDataForm);
